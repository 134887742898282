import { render, staticRenderFns } from "./HaveQuestions.vue?vue&type=template&id=664d6d8c&scoped=true&"
import script from "./HaveQuestions.vue?vue&type=script&lang=js&"
export * from "./HaveQuestions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "664d6d8c",
  null
  
)

export default component.exports